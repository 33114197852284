import React from "react";
import './About.css'; // Import the new CSS file
import ImageSlider from './ImageSlider'; // Import the ImageSlider component

function About() {
  return (
    <div className="content-section">
      <div className="min-container">
        <h2>About Us</h2>
        <p>Welcome to 24 Golf Club, a 24-hour indoor golf simulator facility offering affordable monthly memberships. Book a bay easily through our website or app, self-check in, and enjoy golfing anytime, day or night, in any weather. Our advanced simulators provide a realistic and enjoyable experience that helps improve your golf swing tremendously. Join us to practice and play golf on your schedule, with convenience and comfort</p>


        <div className="location-section">
          <div className="location-text">
            <h3>Our Location</h3>
            <p>13131 Mukilteo Speedway
              <br />Lynnwood, WA 98087
              <br />#102
            </p>
          </div>
          <div className="location-image">
            <ImageSlider />
          </div>
        </div>
        
      </div>
    </div>
  );
}

export default About;
