import React, { useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import logo from "../assets/logo-green.png"; // Ensure you have the logo image in the correct path

function Header() {
  const [isOpen, setIsOpen] = useState(false);

  const notify = () => toast.info("Thank you for your feedback!");
  const toggleMenu = () => setIsOpen(!isOpen);

  return (
    <header>
      <div className="container">
        <div className="logo">
          <img src={logo} alt="24 Golf Club" />
        </div>
        <nav>
          <ul className={isOpen ? "show" : ""}>
            <li>
              <a href="#home">Home</a>
            </li>
            <li>
              <a href="#about">About</a>
            </li>
            <li>
              <a href="#membership">Membership</a>
            </li>
            <li>
              <a href="#contact">Contact</a>
            </li>
          </ul>
          <a className="login-button"
                href="https://app.courtreserve.com/Online/Account/LogIn/12050"
              >
                Member Login
              </a>
        </nav>
        <div className="hamburger" onClick={toggleMenu}>
          &#9776;
        </div>
      </div>
    </header>
  );
}

export default Header;
