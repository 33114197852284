import React from "react";

function Contact() {
  return (
    <div>
      <h2>Contact Us</h2>
      <p>Get in touch with us for more information.</p>
    </div>
  );
}

export default Contact;
