import React from "react";

function Home() {
  return (
    <div className="container">
      <section className="hero">
        <div className="container">
        <h2>24 GOLF CLUB</h2>
        <h1>LYNNWOOD, WA</h1>
        <a href="https://app.courtreserve.com/Online/Memberships/Public/12050">
          <button className="new-member">Become a Member</button>
        </a>
        </div>
      </section>
    </div>
  );
}

export default Home;
