import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './ImageSlider.css';
import image00 from '../assets/golf-bays.jpg';
import image0 from '../assets/FrontDoor.jpeg';
import image1 from '../assets/FloorImage.jpeg';
import image2 from '../assets/SwingExample.jpeg';
import image3 from '../assets/Floor2.jpeg';
import image4 from '../assets/ScoreCard.jpeg';

function ImageSlider() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: true
  };

  return (
    <div className="slider-container">
      <Slider {...settings}>
        <div>
          <img src={image00} alt="Slide 0" />
        </div>
        <div>
          <img src={image0} alt="Slide 0" />
        </div>
        <div>
          <img src={image1} alt="Slide 1" />
        </div>
        <div>
          <img src={image2} alt="Slide 2" />
        </div>
        <div>
          <img src={image3} alt="Slide 3" />
        </div>
        <div>
          <img src={image4} alt="Slide 4" />
        </div>
      </Slider>
    </div>
  );
}

export default ImageSlider;
