import React from "react";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./components/Home";
import About from "./components/About";
import Golf from "./components/Golf";
import Events from "./components/Events";
import Membership from "./components/Membership";
import Contact from "./components/Contact";

function App() {
  return (
    <div>
      <Header />
      <main>
        <div className="content-container">
          <section id="home">
            <Home />
          </section>
          <section id="about">
            <About />
          </section>
          <section id="membership">
            <Membership />
          </section>
          <section id="golf">
            <Golf />
          </section>
          <section id="contact">
            <Contact />
          </section>
        </div>
      </main>
      <Footer />
    </div>
  );
}

export default App;
