import React from "react";

function Footer() {
  return (
    <footer>
      <p>&copy; 2024 24 Golf Club. All rights reserved.</p>
      <div className="social-media">
        <a href="https://www.instagram.com/24golfclub" target="_blank" class="instagram-link">
          <i class="fab fa-instagram"></i>
        </a>

        <a>info@24golfclub.com</a>
      </div>
    </footer>
  );
}

export default Footer;
